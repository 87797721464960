
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n";
import MotivationalCardSections from "@/components/sections/admin/MotivationalCard.vue";
import Header from "@/components/sections/Header.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n({ useScope: "global" });
    useMeta({});
  },
  name: "MotivationalCards",
  components: {
    MotivationalCardSections,
    Header,
  },
});
