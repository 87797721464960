
import { defineComponent } from "vue";

//atoms
import Select from "@/components/atoms/CustomSelect.vue";

// molecules
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";

//svg
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import StickerMock from "@/assets/svg/sticker-mock.svg?inline";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";

export default defineComponent({
  name: "MotivationalCard",
  data() {
    return {
      isModalDelete: false,
      motivationId: null,
      motivationData: {},
      dataStudentsName: [],
    };
  },
  components: {
    //atoms
    Select,

    //moleciles
    DefaultPopup,

    //svg
    SelectArrow,
    SortArrow,
    ArrowBack,
    StickerMock,
    Edit,
    Delete,
  },
  mounted() {
    this.motivationId = this.$route.query.motivation;
    this.getMotivationsCard(this.motivationId);
  },
  methods: {
    getMotivationsCard(id) {
      this.$store.dispatch("motivations/getMotivationCard", id).then(
        (res) => {
          this.motivationData = res.data;
          this.motivationData.students.forEach((item) => {
            this.dataStudentsName.push(item);
          });
        },
        (error) => {
          this.errorMotivations = error.response.data.errors;
        }
      );
    },
    openDeletePoup() {
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
    },
    deleteSticker() {
      this.$store
        .dispatch("motivations/deleteMotivationCard", this.motivationId)
        .then(
          (res) => {
            this.$router.push("/admin/motivational-cards");
          },
          (error) => {
            this.errorInstructors = error.response.data.errors;
          }
        );
    },
    goToEditMotivationalCard(id) {
      this.$router.push("/admin/create-motivational-card?motivation=" + id);
    },
    handleOptionSelected(selectedOption) {
      console.log(selectedOption);
    },
  },
});
